<template>
  <div class="mb-4 my-auto">
    <MultiStepWindow
      contentClass="p-4"
      class="hovering__pad mx-auto my-5"
      style="max-width: 460px"
      :steps="steps"
      :currentStep="step"
    >
      <template #contentHeader>
        <div class="title-part">
          <h1 class="title-part__title">
            <template> Заполните заявку на создание инфопродукта </template>
          </h1>
        </div>
      </template>
      <template #step1>
        <b-form class="mt-4">
          <template v-if="!currentUser.blogger_profile">
            <div class="mb-5">
              <h5 class="required-field-star mb-2">Введите логин</h5>
              <b-input
                v-model="$v.infoproduct.instaname.$model"
                :class="{ error: $v.infoproduct.instaname.$anyError }"
                style="height: 40px !important"
                placeholder="Например, korotylka"
              ></b-input>
            </div>
            <div class="mb-5">
              <h5 class="required-field-star mb-2">Укажите 1-3 темы вашего аккаунта</h5>
              <Multiselect
                :class="{ error: $v.infoproduct.tags.$anyError }"
                v-model="$v.infoproduct.tags.$model"
                placeholder="Выберите из списка"
                :searchable="false"
                :multiple="true"
                :max="3"
                :show-labels="false"
                :options="tags"
                track-by="name"
                label="name"
              />
            </div>
            <div class="mb-5">
              <h5 class="required-field-star mb-2">Охваты сторис</h5>
              <p class="mb-2">
                Заполните поля и загрузите подтверждающий скриншот с охватами сторис
              </p>
              <div class="d-flex justify-content-between align-items-end">
                <div class="mr-3">
                  <p class="fw-500 fs-12 mb-1">Минимум</p>
                  <b-input
                    style="height: 40px !important"
                    :class="{ error: $v.infoproduct.coverage_min.$anyError }"
                    v-model="$v.infoproduct.coverage_min.$model"
                  ></b-input>
                </div>
                <div class="mr-3">
                  <p class="fw-500 fs-12 mb-1">Максимум</p>
                  <b-input
                    style="height: 40px !important"
                    :class="{ error: $v.infoproduct.coverage_max.$anyError }"
                    v-model="$v.infoproduct.coverage_max.$model"
                  ></b-input>
                </div>
                <single-file-input
                  :buttonStyle="'height: 40px !important'"
                  :handleUpload="true"
                  :uploadUrl="'/api/producing/upload-coverage-screen/'"
                  v-model="$v.infoproduct.coverage_screen.$model"
                  :class="{ error: $v.infoproduct.coverage_screen.$anyError }"
                ></single-file-input>
              </div>
            </div>
          </template>
          <div class="mb-5">
            <h5 class="required-field-star mb-2">Выберите вид инфопродукта:</h5>
            <div class="align-items-start d-flex flex-wrap justify-content-between">
              <template v-for="option in infoProductsOptions.actions.POST.kind.choices">
                <LeftPointedRadioButton
                  :key="`${option.value}-o124kirTpFrm43s`"
                  :variant="option.value === infoproduct.kind ? 'primary' : 'outline-default'"
                  @click="
                    () => {
                      infoproduct.kind = option.value;
                    }
                  "
                  :class="{ error: $v.infoproduct.kind.$anyError }"
                  class="mr-1 mt-2 fmt-rd ph-36 text-left"
                  style="line-height: 13px"
                >
                  {{ option.display_name }}
                </LeftPointedRadioButton>
              </template>
            </div>
          </div>
          <div
            class="input__symbol--count position-relative mb-5"
            :symbolCount="(infoproduct.description || '').length"
            :symbolLimit="220"
          >
            <h5 class="required-field-star mb-2">
              Коротко опишите инфопродукт, который вы хотите создать и запустить.
            </h5>
            <b-form-textarea
              maxlength="220"
              style="height: 180px"
              :class="{ error: $v.infoproduct.description.$anyError }"
              v-model="infoproduct.description"
            ></b-form-textarea>
          </div>
          <div class="mb-5">
            <h5 class="required-field-star mb-3">
              Хотите запуск на своих охватах или с привлечением стороннего блогера?
            </h5>

            <div class="d-block d-xsm-flex">
              <left-pointed-radio-button
                class="w-100 w-xsm-50 mr-xsm-2"
                style="line-height: 11px; height: 40px !important"
                :class="{ error: $v.infoproduct.standalone.$anyError }"
                :variant="
                  !infoproduct.standalone && typeof infoproduct.standalone !== 'object'
                    ? 'primary'
                    : 'outline-default'
                "
                @click="infoproduct.standalone = false"
              >
                Свои охваты
              </left-pointed-radio-button>
              <left-pointed-radio-button
                class="w-100 w-xsm-50 ml-xsm-2 mt-2 mt-xsm-0"
                style="line-height: 11px; height: 40px !important"
                :class="{ error: $v.infoproduct.standalone.$anyError }"
                :variant="infoproduct.standalone ? 'primary' : 'outline-default'"
                @click="infoproduct.standalone = true"
              >
                С привлечением
              </left-pointed-radio-button>
            </div>
          </div>
          <div class="mb-5">
            <h5 class="required-field-star mb-2">Желаемый месяц старта продаж.</h5>
            <div class="align-items-start d-flex flex-wrap justify-content-between">
              <template v-for="option in allowedMonths">
                <LeftPointedRadioButton
                  :key="`${option.value}-o124kirTpFrm43s`"
                  :variant="option.value === infoproduct.month ? 'primary' : 'outline-default'"
                  @click="
                    () => {
                      infoproduct.month = option.value;
                    }
                  "
                  :class="{ error: $v.infoproduct.month.$anyError }"
                  class="mr-1 mt-2 fmt-rd ph-36 text-left"
                  style="line-height: 13px"
                >
                  {{ option.display_name }}
                </LeftPointedRadioButton>
              </template>
            </div>
          </div>
          <div class="mb-5">
            <h5 class="required-field-star mb-2">Укажите текущую стадию создания инфопродукта.</h5>
            <div>
              <template v-for="option in infoProductsOptions.actions.POST.stage.choices">
                <LeftPointedRadioButton
                  :key="`${option.value}-o124kirTpFrm43s`"
                  :variant="option.value === infoproduct.stage ? 'primary' : 'outline-default'"
                  @click="
                    () => {
                      infoproduct.stage = option.value;
                    }
                  "
                  :class="{ error: $v.infoproduct.stage.$anyError }"
                  class="mr-1 mt-2 w-100 ph-36 text-left"
                  style="line-height: 13px; height: 40px !important"
                >
                  {{ option.display_name }}
                </LeftPointedRadioButton>
              </template>
            </div>
          </div>
          <div>
            <h5 class="required-field-star mb-2">
              Нужно ли привлекать экспертов по теме инфопродукта для его создания?
            </h5>
            <div class="align-items-start d-flex flex-wrap justify-content-between">
              <template
                v-for="option in [
                  { display_name: 'Да', value: true },
                  { display_name: 'Нет', value: false },
                ]"
              >
                <LeftPointedRadioButton
                  :key="`${option.value}-o124kirTpFrm43s`"
                  :variant="
                    option.value === infoproduct.involve_experts ? 'primary' : 'outline-default'
                  "
                  @click="
                    () => {
                      infoproduct.involve_experts = option.value;
                    }
                  "
                  :class="{ error: $v.infoproduct.involve_experts.$anyError }"
                  class="mr-1 mt-2 fmt-rd ph-36 text-left"
                  style="line-height: 13px; height: 40px !important"
                >
                  {{ option.display_name }}
                </LeftPointedRadioButton>
              </template>
            </div>
          </div>
        </b-form>
        <b-form class="mt-4">
          <div class="mb-5">
            <h5 class="required-field-star mb-2">Опыт запуска инфопродуктов.</h5>
            <div>
              <template v-for="option in infoProductsOptions.actions.POST.experience.choices">
                <LeftPointedRadioButton
                  :key="`${option.value}-o124kirTpFrm43s`"
                  :variant="option.value === infoproduct.experience ? 'primary' : 'outline-default'"
                  @click="
                    () => {
                      infoproduct.experience = option.value;
                    }
                  "
                  :class="{ error: $v.infoproduct.experience.$anyError }"
                  class="mr-1 mt-2 w-100 ph-36 text-left"
                  style="line-height: 13px; height: 40px !important"
                >
                  {{ option.display_name }}
                </LeftPointedRadioButton>
              </template>
            </div>
          </div>
          <div
            v-if="infoproduct.experience && infoproduct.experience === 2"
            class="input__symbol--count position-relative mb-5"
            :symbolCount="(infoproduct.previous_products || '').length"
            :symbolLimit="220"
          >
            <h5 class="required-field-star mb-2">Что запускали?</h5>
            <b-form-textarea
              maxlength="220"
              style="height: 180px"
              :class="{ error: $v.infoproduct.previous_products.$anyError }"
              v-model="infoproduct.previous_products"
            ></b-form-textarea>
          </div>
          <div v-if="infoproduct.experience && infoproduct.experience === 2" class="mb-5">
            <h5 class="required-field-star mb-2">
              На какую сумму в рублях был ваш самый крупный запуск?
            </h5>
            <div class="rouble-inp position-relative">
              <b-form-input
                type="number"
                class="w-100 ph-36"
                placeholder="Введите сумму"
                v-model="$v.infoproduct.max_price.$model"
                :class="{ error: $v.infoproduct.max_price.$anyError }"
              ></b-form-input>
            </div>
          </div>
          <div
            v-if="infoproduct.experience && infoproduct.experience === 2"
            class="input__symbol--count position-relative mb-5"
            :symbolCount="(infoproduct.refuse_products_reasons || '').length"
            :symbolLimit="400"
          >
            <h5 class="required-field-star mb-2">
              Если запускали свои инфопродукты с продюсерами, то укажите, почему от них отказались?
            </h5>
            <b-textarea
              maxlength="400"
              :class="{ error: $v.infoproduct.refuse_products_reasons.$anyError }"
              v-model="infoproduct.refuse_products_reasons"
              style="height: 180px"
            ></b-textarea>
          </div>
          <div class="mb-5">
            <h5 class="required-field-star mb-2">
              Чтобы обсудить проект, выберите дату и время для звонка от нашего специалиста
            </h5>
            <p style="color: #6a7b83">Звонок обязателен, так мы подтверждаем реальность заявки</p>
            <b-row>
              <b-col class="mt-2" cols-md="7">
                <label class="fs-16">Дата</label>
                <b-form-datepicker
                  locale="ru"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                  style="height: 40px !important; min-width: 185px"
                  placeholder="Выберите дату"
                  :class="{ error: $v.infoproduct.call_date.$anyError }"
                  v-model="$v.infoproduct.call_date.$model"
                ></b-form-datepicker>
              </b-col>
              <b-col class="mt-2">
                <label class="fs-16">Время</label>
                <b-form-timepicker
                  style="height: 40px !important; min-width: 120px"
                  placeholder="Время"
                  :class="{ error: $v.infoproduct.call_time.$anyError }"
                  v-model="$v.infoproduct.call_time.$model"
                ></b-form-timepicker>
              </b-col>
            </b-row>
          </div>
          <div class="mb-5">
            <h5 class="required-field-star mb-2">
              В каком мессенджере вам будет удобнее вести диалог?
            </h5>
            <b-form-select
              :options="infoProductsOptions.actions.POST.messenger.choices"
              class="ph-40"
              value-field="value"
              text-field="display_name"
              style="height: 40px"
              :class="{ error: $v.infoproduct.messenger.$anyError }"
              v-model="$v.infoproduct.messenger.$model"
            ></b-form-select>
          </div>
          <div class="mb-5">
            <h5 class="required-field-star mb-2">Почта для связи</h5>
            <b-form-input
              class="ph-40"
              style="height: 40px"
              :class="{ error: $v.infoproduct.email.$anyError }"
              v-model="$v.infoproduct.email.$model"
            ></b-form-input>
          </div>
          <div class="mb-4">
            <h5 class="required-field-star mb-2">Телефон для связи</h5>
            <b-form-input
              class="ph-40"
              style="height: 40px"
              :class="{ error: $v.infoproduct.phonenumber.$anyError }"
              v-model="$v.infoproduct.phonenumber.$model"
            ></b-form-input>
          </div>
          <div></div
        ></b-form>
      </template>
      <template #footer>
        <div class="p-4 d-flex justify-content-between">
          <b-button
            @click="
              () => {
                if (step > 1) {
                  step -= 1;
                }
              }
            "
            class="px-5 mr-2"
            variant="outline-default"
            >Назад</b-button
          >
          <b-button
            variant="yellow"
            style="line-height: 11px"
            @click="submit"
            class="ph-42 px-5 w-50"
            >Опубликовать заявку</b-button
          >
        </div>
      </template>
    </MultiStepWindow>
  </div>
</template>

<script>
/* eslint-disable func-names */

import Multiselect from 'vue-multiselect';
import { mapState, mapMutations } from 'vuex';
import { required, minLength, sameAs, requiredIf, email } from 'vuelidate/lib/validators';
import auth from '@main/api/users';
import producing from '@main/api/producing';
import ReviewAdTypeSelect from '../forms/ReviewAdTypeSelect.vue';
import MultiStepWindow from '../reusable/windows/MultiStepWindow.vue';
import LeftPointedRadioButton from '../reusable/elements/buttons/LeftPointedRadioButton.vue';
import SingleFileInput from '../forms/SingleFileInput.vue';

export default {
  data: () => ({
    step: 1,
    steps: 1,
    infoproduct: {
      kind: null,
      description: null,
      standalone: null,
      involve_experts: null,
      month: null,
      stage: null,
      experience: null,
      previous_products: null,
      max_price: null,
      refuse_products_reasons: null,
      email: null,
      phonenumber: null,
      tags: [],
      coverage_min: null,
      coverage_max: null,
      coverage_screen: null,
      instaname: null,
      messenger: null,
      call_date: null,
      call_time: null,
    },
  }),
  validations: {
    infoproduct: {
      tags: {
        required: requiredIf(function () {
          return !this.currentUser.blogger_profile;
        }),
      },
      instaname: {
        required: requiredIf(function () {
          return !this.currentUser.blogger_profile;
        }),
      },
      coverage_min: {
        required: requiredIf(function () {
          return !this.currentUser.blogger_profile;
        }),
      },
      coverage_max: {
        required: requiredIf(function () {
          return !this.currentUser.blogger_profile;
        }),
      },
      coverage_screen: {
        required: requiredIf(function () {
          return !this.currentUser.blogger_profile;
        }),
      },
      kind: { required },
      description: { required },
      standalone: { required },
      involve_experts: { required },
      month: { required },
      stage: { required },
      experience: { required },
      max_price: {
        required: requiredIf(function () {
          return this.infoproduct.experience === 2;
        }),
      },
      previous_products: {
        required: requiredIf(function () {
          return this.infoproduct.experience === 2;
        }),
      },
      refuse_products_reasons: {
        required: requiredIf(function () {
          return this.infoproduct.experience === 2;
        }),
      },
      email: { required },
      phonenumber: { required },
      messenger: { required },
      call_date: { required },
      call_time: { required },
    },
    step1group: [
      'infoproduct.kind',
      'infoproduct.description',
      'infoproduct.standalone',
      'infoproduct.month',
      'infoproduct.stage',
      'infoproduct.involve_experts',
      'infoproduct.experience',
      'infoproduct.previous_products',
      'infoproduct.max_price',
      'infoproduct.kind',
      'infoproduct.refuse_products_reasons',
      'infoproduct.email',
      'infoproduct.phonenumber',
    ],
    /*
    step2group: [
      'infoproduct.experience',
      'infoproduct.previous_products',
      'infoproduct.max_price',
      'infoproduct.kind',
      'infoproduct.refuse_products_reasons',
      'infoproduct.email',
      'infoproduct.phonenumber',
      'model.blogger_profile.coverage_min',
      'model.blogger_profile.coverage_max',
    ],
    */
  },
  methods: {
    ...mapMutations('notifications', ['showMessage']),
    async submit() {
      this.$v.$touch();
      this.$v.step1group.$touch();
      console.log(this.$v);
      if (this.$v.$anyError) {
        this.showMessage({ title: 'Ошибка! Проверьте правильность данных', icon: 2 });
        return;
      }
      try {
        await auth.updateMe(this.model);
        await producing.infoProducts.create(this.infoproduct);
      } catch (e) {
        // eslint-disable-next-line no-alert
        if (e.response.status === 403) {
          this.showMessage({ title: e.response.data.detail, icon: 2 });
          return;
        }
        this.showMessage({ title: 'Ошибка! Проверьте правильность данных', icon: 2 });
        return;
      }
      this.showMessage({ title: 'Заявка на создание инфопродукта успешно отправлена!', icon: 1 });
      const next = await this.$router.$getNextPage(this.$router, this.$route);
      if (!next) {
        await this.$router.push({ name: 'reviews' });
      }
    },
  },
  computed: {
    ...mapState('producing', ['producerProfilesOptions', 'infoProductsOptions']),
    ...mapState(['currentUser', 'tags']),
    allowedMonths() {
      const resultArray = [];
      const count = 6;
      const current = new Date().getMonth();
      let month = current;
      for (let i = 0; i < count; i += 1) {
        if (month < 12) {
          month += 1;
        } else {
          month = 1;
        }
        resultArray.push(this.infoProductsOptions.actions.POST.month.choices[month - 1]);
      }
      console.log(resultArray);
      return resultArray;
    },
  },
  components: { MultiStepWindow, LeftPointedRadioButton, Multiselect, SingleFileInput },
  mounted() {
    this.model = this.lodash.cloneDeep(this.currentUser);
    this.infoproduct.phonenumber = this.currentUser.profile.phone;
    this.infoproduct.email = this.currentUser.profile.email;
  },
};
</script>

<style lang="scss" scoped>
.fmt-rd {
  width: 48% !important;
  min-width: 110px !important;
  height: 40px !important;
}
::v-deep .multiselect__tag {
  background: #eaeef0 !important;
}

::v-deep .custom-radio.b-custom-control-lg,
.input-group-lg .custom-radio {
  font-size: 14px !important;
  line-height: 25px;
  margin: 2px 0;
}
::v-deep .bv-no-focus-ring {
  border: none !important;
}
::v-deep .error .custom-radio.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-radio .custom-control-label::before {
  border: 1px solid red;
}
::v-deep .custom-radio.b-custom-control-lg .custom-control-label {
  margin-right: 10px;
}
::v-deep .multiselect__tag-icon:after {
  color: #192229;
}
::v-deep .b-form-btn-label-control.form-control > .form-control {
  line-height: 30px !important;
}
</style>
